.header {
    text-align: center;
    border-bottom: 1px gray solid;
    padding: 30px 0;
}

.header a {
    text-decoration: none;
    color: black;
}

.header a:hover, .header a:active {
    text-decoration: underline;
}