main {
    width: 67%;
    padding: 20px;
}

.main > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.addnote {
    border: 1px black solid;
    display: block;
    min-width: 30%;
    margin: 20px;
    padding: 20px;
}